import React from "react";
// Components
import PagesMain from "components/Pages/Main";
// Locales ES
import metatagsLocale from "locales/es/metatags.json";
import navbarLocale from "locales/es/navbar.json";
import headerLocale from "locales/es/header.json";
import introLocale from "locales/es/intro.json";
import featuresLocale from "locales/es/features.json";
import portfolioLocale from "locales/es/portfolio.json";
import pricingLocale from "locales/es/pricing.json";
import linksLocale from "locales/es/links.json";

const locales = {
  metatagsLocale,
  navbarLocale,
  headerLocale,
  introLocale,
  featuresLocale,
  portfolioLocale,
  pricingLocale,
  linksLocale
};

const IndexPage = () => <PagesMain locales={locales} />;

export default IndexPage;
